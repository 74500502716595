<template>
  <v-container fluid id="data-tables" tag="section">
    <base-material-card
      color="primary"
      icon="mdi-alarm"
      inline
      class="px-5 py-3"
    >
      <template v-slot:after-heading>
        <v-toolbar flat>
          <v-toolbar-title>Drills</v-toolbar-title>
          <v-spacer />
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            style="max-width: 300px"
            class="mr-5"
          />
          <v-select
            v-model="searchStatus"
            :items="status"
            item-value="label"
            item-text="label"
            label="Status"
            style="max-width: 150px"
            clearable
            @change="getDrills"
          ></v-select>
        </v-toolbar>
      </template>
      <v-divider class="mt-3" />
      <v-data-table
        class="aq-tbl"
        :loading="loadingPage"
        :headers="headers"
        :items="items"
        :search.sync="search"
      >
        <template #[`item.lock`]="{ item }">
          <div class="text-center" v-if="item.customer_active">
            {{ item.lock ? 'Premium' : 'Free' }}
          </div>
        </template>
        <template #[`item.updated`]="{ item }">
          <div class="text-center">
            <div v-if="item.updated">
              {{ item.updated | formatDateNow }}
              <span v-if="item.update_user_name">
                by {{ item.update_user_name }}
              </span>
            </div>
            <div v-else>
              {{ item.created | formatDateNow }}
              <span v-if="item.add_user_name">by {{ item.add_user_name }}</span>
            </div>
          </div>
        </template>
        <template #[`item.actions`]="{ item }">
          <v-btn fab icon x-small :to="'/list/d/view/' + item.id">
            <v-icon small>mdi-eye</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </base-material-card>
  </v-container>
</template>

<script>
import { mapState } from 'vuex';
import { drillsCollection, usersCollection } from '@/utils/firebase';
import generic from '@/mixins/generic.js';

export default {
  name: 'DrillsList',
  mixins: [generic],
  data: () => ({
    items: [],
    search: null,
    searchStatus: null,
    loadingPage: false,
    users: {},
  }),
  computed: {
    ...mapState({
      user: (state) => state.user.user,
      orgId: (state) => state.user.orgId,
      orgName: (state) => state.user.orgName,
      customerId: (state) => state.user.customerId,
      customerName: (state) => state.user.customerName,
    }),
    headers() {
      let header = [
        { text: 'Name', value: 'name' },
        { text: 'Status', value: 'status', width: '200px' },
        { text: 'Access', value: 'lock', width: '200px' },
        { text: 'Updated', value: 'updated', width: '300px' },
        { text: 'Actions', value: 'actions', sortable: false, width: '120px' },
      ];
      if (this.customerId) {
        header = [
          { text: 'Name', value: 'name' },
          { text: 'Owner', value: 'owner', width: '200px' },
          { text: 'Status', value: 'status', width: '200px' },
          { text: 'Updated', value: 'updated', width: '300px' },
          {
            text: 'Actions',
            value: 'actions',
            sortable: false,
            width: '120px',
          },
        ];
      }

      return header;
    },
  },
  watch: {
    orgId: function (val) {
      this.getUsers();
      this.getVariables(val);
    },
  },
  created() {
    this.getUsers();
    this.getVariables();
  },
  methods: {
    async getUsers() {
      try {
        let resSnap = usersCollection;
        if (this.orgId != null) {
          resSnap = resSnap.where('org_id', '==', this.orgId);
        }
        resSnap = resSnap.orderBy('name', 'desc');
        let querySnapshot = await resSnap.get();
        if (querySnapshot.docs.length > 0) {
          querySnapshot.forEach((doc) => {
            let post = doc.data();
            let user = {};
            user[doc.id] = post.name;
            this.users = Object.assign(this.users, user);
          });
        }
        this.getDrills();
      } catch (err) {
        this.$store.dispatch('notification/add', {
          type: 'error',
          message: `An error occurred : ${err.message}`,
        });
      }
    },
    async getDrills() {
      this.loadingPage = true;
      let resSnap = drillsCollection;
      if (this.orgId != null) {
        resSnap = resSnap.where('org_id', '==', this.orgId);
      }
      if (this.searchStatus != null) {
        resSnap = resSnap.where('status', '==', this.searchStatus);
      }
      this.items = [];
      resSnap.orderBy('created', 'desc').onSnapshot((querySnapshot) => {
        let postsArray = [];
        querySnapshot.forEach((doc) => {
          let post = doc.data();
          post.id = doc.id;
          post.owner = '';
          if (this.customerName) post.owner = this.customerName;

          post.update_user_name = post.update_user_id
            ? this.users[post.update_user_id]
            : '';
          post.add_user_name = post.add_user_id
            ? this.users[post.add_user_id]
            : '';
          post.removeItem = true;
          post.customer_active = true;
          if (
            this.customerId !== '' &&
            this.customerId !== undefined &&
            this.customerId !== null
          ) {
            if (
              post.customer_id === undefined ||
              post.customer_id === null ||
              post.customer_id === ''
            ) {
              post.owner = this.orgName;
              post.customer_active = false;
              post.removeItem = false;
            } else if (post.customer_id === this.customerId) {
              post.removeItem = false;
            }

            if (!post.removeItem) postsArray.push(post);
          } else if (
            post.customer_id === undefined ||
            post.customer_id === null ||
            post.customer_id === ''
          ) {
            postsArray.push(post);
          }
        });
        this.items = postsArray;
        this.loadingPage = false;
      });
    },
  },
};
</script>
